import { Component, HostListener, ChangeDetectorRef, OnInit, Inject, APP_INITIALIZER, ApplicationInitStatus  } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive'
import { Platform } from '@ionic/angular';
import { ToolsService } from './services/tools.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  // some fields to store our state so we can display it in the UI
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  idleTime: any;
  mobile = false
  
  constructor(public platform: Platform, public tools: ToolsService, private idle: Idle, keepalive: Keepalive, cd: ChangeDetectorRef,
    @Inject(APP_INITIALIZER) public appInit: ApplicationInitStatus, private router: Router) {
    platform.ready().then(() => {
      this.tools.mobile = false;
      console.log("Width: ", platform.width());
        if (platform.width() <= 600){
          this.tools.mobile = true;
        }
        if (!this.tools.mobile){
          console.log("StandBYSSSS!!!!!!")
          // set idle parameters
          idle.setIdle(5); // how long can they be inactive before considered idle, in seconds
          idle.setTimeout(120); // how long can they be idle before considered timed out, in seconds
          idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active
    
          
        }
    });
    this.mobile = this.platform.is('ios') || this.platform.is('android') ? true : false;
    console.log("Tools Mobile: ", this.tools.mobile)
    // do something when the user becomes idle
    idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
    });
    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      console.log(`${this.idleState} ${new Date()}`)
      this.countdown = null;
      cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    idle.onTimeout.subscribe(() => this.goStandBy() );
    // do something as the timeout countdown does its thing
    idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

    // set keepalive parameters, omit if not using keepalive
    keepalive.interval(15); // will ping at this interval while not idle, in seconds
    keepalive.onPing.subscribe(() => this.lastPing = new Date()); // do something when it pings
    

    
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth <= 600){
      this.tools.mobile = true;
    }
    if(event.target.innerWidth > 600){
      this.tools.mobile = false;
    }
    console.log("Resize: ", this.tools.mobile)
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  ngOnInit(): void {
    // right when the component initializes, start reset state and start watching
    this.reset();
  }

  resetTimeout(){
    this.reset();
  }

  goStandBy(){
    console.log("Timed Out");
    this.idleState = "TIMED_OUT";
    this.router.navigate(["/standby"])
  }
  
}
